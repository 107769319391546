import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from '../PeopleHome/styles';
import { StyledModal, StyledHeader } from '../AddInputGoals/styles';
import { CheckMark,/* SubmitContainer*/ } from '../AddWellnessInitiative/styles';
import { Padding, CommonContainer } from "../LegalUpdatesPopup/style";
// import {/*biometricFormFields,*/ personaldetails} from '../../../mockData.json';
import {/*SectionHeader,*/ SectionWrapper, SectionContainer, StyledBody, FlexWrapper} from './styles';
import {connect} from 'react-redux';
import { isNull } from 'lodash';
import Waiting from '../Waiting';
import {getUserBiometricDetails} from "../../redux/actions/peopleHomeActions";
import { findMonth, dateOrdinal, convertMonthFromDate} from '../../utils/methods';
import moment from "moment";
import { personaldetails } from '../../../mockData';
import { withTranslation } from 'react-i18next';
class BiometricDetails extends React.Component{
  constructor(){
    super();
    this.state = {
      checked: false,
      birthday: '',
      fname: '',
      lname: '',
      address1: '',
      city: '',
      state: '',
      mail: '',
      companyName: '',
      phoneNumber: '',
      postalCode: '',
      gender: '',
      bloodPressureDia: '',
      bloodPressureSys: '',
      bmi: '',
      cholestrolHdl: '',
      cholestrolLdl: '',
      cholestrolTotal: '',
      fasting: '',
      glucose: '',
      heightInFt: '',
      heightInInch: '',
      triglycerides: '',
      waistCircumference: '',
      weight: '',
    }
  }
  componentDidMount(){
    const {user, getUserBiometricDetails, /*viewData*/} = this.props;
    getUserBiometricDetails(user.uid)
    // viewData && getUserBiometricDetails(user.uid);
  }
  getFormattedBirthday = (date) => {
    let formattedDate = [];
    const birthday = date.split('-');
    formattedDate = birthday.length > 0 ?  [birthday[1], birthday[2], birthday[0]] : [];
    return formattedDate;
  };
  getFormattedMonth = (monthValue) => {
    switch (monthValue) {
    case 0:
      return `${12}`;
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return `0${monthValue}`;
    case 10:
    case 11:
      return `${monthValue}`;
    default:
      return <div> </div>
    }
  };
  componentDidUpdate(prevProps) {
    const {biometricDetails, /*viewData*/} = this.props;
    if( biometricDetails !== prevProps.biometricDetails) {
      this.setBiometricDetails(biometricDetails);
    }
  }
  setBiometricDetails = (biometricDetails) =>{
    biometricDetails && Object.keys(biometricDetails['user_details']).map((fieldName)=>{
      if(fieldName == 'birthday') {
        let formattedBirthdate = this.getFormattedBirthday(biometricDetails['user_details'][fieldName]);
        this.setState({ [fieldName] : formattedBirthdate.join('-')}, ()=>{
        })
      }
      else {
        this.setState({ [fieldName] : biometricDetails['user_details'][fieldName]}, ()=>{
        })
      }
    });
    biometricDetails && Object.keys(biometricDetails['biometric_details']).map((fieldName)=>{
      if(fieldName == 'height') {
        let height = biometricDetails['biometric_details']['height'];
        if(!isNull(height)){
          this.setState({heightInFt: height.split('.')[0], heightInInch: height.split('.')[1]})
        }
      }
      else {
        this.setState({ [fieldName] :  isNull(biometricDetails['biometric_details'][fieldName]) ? '' : biometricDetails['biometric_details'][fieldName]}, ()=>{
        })
      }
    });
  }
  changeCheckData = () => {
    this.setState({
      checked: !this.state.checked
    })
  }
  showCheckboxData = (checked) => {
    const { user} = this.props;
    const today = new Date();
    return(<CommonContainer borderTop={1}>
      <Padding color={1}>
        <div className="check">
          <CheckMark checked={checked} onClick={() => this.changeCheckData()}/>
        </div>
        <div className="name">
          {`${this.props.t("I")} ${user.fname} ${this.props.t("confirm the above details are accurate for my Biometric that I completed on")} ${convertMonthFromDate(moment(today).format('MMM DD, YYYY'), this.props)} ${this.props.t("with my Doctor or Physician")}`}
        </div>
      </Padding>
    </CommonContainer>)
  };
  showSubHeadSentence = () => (
    <CommonContainer borderTop={1}>
      <div className="name">
        {this.props.t(`Please confirm the below details are correct`)}
      </div>
    </CommonContainer>
  );
  SubmitDetails = () => {
    const {closeModal, biometricSubmitForm} = this.props;
    biometricSubmitForm();
    closeModal();
  }

  showPersonalDetails = (biometricFields) => {
    // const {userBiometricData, viewData} = this.props;
    const {lname,  fname, address1, city, state, mail, companyName, phoneNumber, postalCode,  bloodPressureDia, bloodPressureSys,bmi, cholestrolHdl, cholestrolLdl, cholestrolTotal, fasting, glucose, height, triglycerides, waistCircumference, weight, heightInFt, heightInInch, birthday, gender,createdAt} = this.state;
    const values = {lname, fname, address1, city, state, mail, companyName, phoneNumber, postalCode, bloodPressureDia, bloodPressureSys,bmi, cholestrolHdl, cholestrolLdl, cholestrolTotal, fasting, glucose, height, triglycerides, waistCircumference, weight, heightInFt, heightInInch, birthday, gender};
    let updatedData = values;
    let userData = values;
    userData['height'] = updatedData.heightInFt +'.'+ (updatedData.heightInInch % 12);
    return (
      <FlexWrapper>
        {biometricFields.map((section, index) =>(
          <SectionWrapper key={section.title + index}>
            {
              section.inputFields.map((field, index) => (
                (field.name === 'fasting') ? <SectionContainer key={field.name + index}>
                  <span>{userData[`${field.name}`] === 1  ? this.props.t("Fasting") : this.props.t('Non-Fasting')}</span><span/>
                </SectionContainer> : <SectionContainer key={field.name + index}>
                  <span>{this.props.t(field.label)}:</span>
                  <span>{this.props.t(userData[`${field.name}`])}</span>
                </SectionContainer>
              ))
            }
          </SectionWrapper>))}
        <div className='completed'>
          { `${this.props.t("Completed on")} ${convertMonthFromDate(moment(createdAt).format('MMM DD, YYYY'), this.props)}`}
        </div>
      </FlexWrapper>
    )
  }

  render() {
    const {closeModal, showModal, biometricDetails, viewData} = this.props;
    // const {checked} = this.state;
    if(!biometricDetails){
      return <Waiting/>;
    }
    let date = new Date(biometricDetails && biometricDetails['biometric_details'].physicianFormTimestamp);
    let getSubmitedDate = date ? `${findMonth(date.getMonth())} ${date.getDate()}${dateOrdinal(date.getDate())}, ${date.getFullYear()}` : '';
    return (
      <StyledModal show={showModal} onHide={() => closeModal()} width={'700px'}>
        <StyledHeader uppercase={1} closeButton>
          <div className="pdfHeader">{this.props.t("Biometric Details")}</div>
          { getSubmitedDate !== '' && viewData ? <div className="subHeader">{this.props.t("Submitted On")} {getSubmitedDate}</div> : null}
        </StyledHeader>
        <StyledBody>
          {this.showPersonalDetails(personaldetails)}
          {/* {viewData ? null : this.showSubHeadSentence()}
          <SectionHeader>Personal Details:</SectionHeader>
          {this.showPersonalDetails(biometricFormFields)}
          <SectionHeader>Biometric Details:</SectionHeader>
          {this.showPersonalDetails(personaldetails)}
          {viewData ? null : this.showCheckboxData(checked)}
          {viewData ? null : <SubmitContainer>
            <Button className="biometric-button" onClick = {() => this.SubmitDetails()} disabled={viewData ? true : !checked}>
              Submit
            </Button>
          </SubmitContainer>} */}
        </StyledBody>
      </StyledModal>
    );
  }
}
BiometricDetails.propTypes =  {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  biometricSubmitForm: PropTypes.func,
  getUserBiometricDetails: PropTypes.func,
  userBiometricData: PropTypes.object,
  biometricDetails: PropTypes.object,
  viewData: PropTypes.number,
  user: PropTypes.object,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  biometricDetails: state.peopleHome.biometricDetails,
  user: state.profileData.user,
});
const mapDispatchToProps = (dispatch) => ({
  getUserBiometricDetails: (userId) => dispatch(getUserBiometricDetails(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(BiometricDetails));
