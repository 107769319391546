import styled from 'styled-components';
import {Modal} from "react-bootstrap";
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';

const SectionHeader = styled.div`
  float: left;
  width: 100%;
  display: block;
  font-size: 18px;
  color: rgb(43,81,102);
  font-family: ${MediumFont};
  font-weight: 500;
  font-weight: normal;
  position: relative;
  pointer-events: none;
  margin-top: 20px;
`;

const SectionWrapper = styled.div`
width: 48%
float: left;
margin-right: 10px;
margin-bottom: 20px;
background: rgb(232, 240, 254);
min-height: 247px;
@media (max-width: 767px) {
  width: 100%;
}
`;

const SectionContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding: 10px;
  background: rgb(232, 240, 254);
  min-height: 40px;
  span{
    width: auto;
    display: inline-block;
    font-size: 14px;
    color: rgb(43,81,102);
    font-family: ${MediumFont};
    margin-right: 10px;
    &:last-child{
      font-family: ${RegularFont};
    }
  }
  &:last-child{
    border-bottom: none;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledBody = styled(Modal.Body)`
  padding: 10px 15px 30px 25px;
`;
const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .completed{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
  }
`;
export {StyledBody, SectionHeader, SectionWrapper, SectionContainer, FlexWrapper};