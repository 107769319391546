import React, { lazy, Component, Suspense } from "react";
import { DashboardLayout } from "../CompanyDashBoard/styles";
// import { StyledTabs } from "../ChallengeDetailsV2/styles";
import { Tab } from "react-bootstrap";
import PropTypes from "prop-types";
const InformationTab = lazy(() => import("./InformationTab"));
const InvoiceTab = lazy(() => import("./InvoiceTab"));
import {
  getAllPlans,
  getCompanySummary,
  getAllCardsForCustomer,
  getAllAddons,
  getCompanySubscriptionAndAddons,
} from "../../redux/actions";
import { connect } from "react-redux";
import { isNull, isUndefined } from "lodash";
import { COMPANY_NAME } from "../../utils/config";
import Waiting from "../Waiting";
import { StyledTabs } from "../CompanyDashBoardV2/styles";
class BillingTab extends Component {
  componentDidMount() {
    const { companyInfo, getCompanySummary, getAllAddons } = this.props;
    getCompanySummary(companyInfo.id);
    getAllAddons(companyInfo.id);
  }
  componentDidUpdate(prevProps) {
    const {
      companyAndCustomer,
      getAllCardsForCustomer,
      companyInfo,
      getCompanySubscriptionAndAddons,
    } = this.props;
    if (
      prevProps.companyAndCustomer !== companyAndCustomer &&
      !isNull(companyAndCustomer) &&
      !isUndefined(companyAndCustomer)
    ) {
      const { company_stripe_customers: customer } = companyAndCustomer;
      if (
        !isNull(customer) &&
        !isUndefined(customer) &&
        COMPANY_NAME !== "Woliba"
      )
        getAllCardsForCustomer(customer.id);
      getCompanySubscriptionAndAddons(companyInfo.id);
    }
  }
  render() {
    const { companyAndCustomer, companyInfo, yearList } = this.props;
    return (
      <DashboardLayout style={{ /*float: "left",*/ width: "1246px", margin:"auto",marginTop:"30px",float:"unset" }}>
        <Suspense fallback={<Waiting />}>
          <StyledTabs defaultActiveKey={4} id="styledTabs">

            <Tab
              eventKey={4}
              title="Invoices"
              tabClassName="fourth-tab"
              mountOnEnter={true}
            >
              <InvoiceTab
                companyInfo={companyInfo}
                companyAndCustomer={companyAndCustomer}
                yearList={yearList}
              />
            </Tab>
            <Tab
              eventKey={3}
              title="Information"
              tabClassName="third-tab"
              mountOnEnter={true}
            >
              <InformationTab
                companyInfo={companyInfo}
                companyAndCustomer={companyAndCustomer}
              />
            </Tab>

          </StyledTabs>
        </Suspense>
      </DashboardLayout>
    );
  }
}
BillingTab.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  getAllPlans: PropTypes.func,
  getCompanySummary: PropTypes.func,
  getAllCardsForCustomer: PropTypes.func,
  allPlans: PropTypes.array,
  companyAndCustomer: PropTypes.object,
  isLoading: PropTypes.bool,
  yearList: PropTypes.array,
  allAddons: PropTypes.array,
  getAllAddons: PropTypes.func,
  getCompanySubscriptionAndAddons: PropTypes.func,
  currentSubsciptionDetails: PropTypes.object,
};
const mapStateToProps = (state) => ({
  isLoading: state.stripeData.isLoading,
  allPlans: state.stripeData.allPlans,
  companyAndCustomer: state.stripeData.companyAndCustomer,
  allAddons: state.stripeData.allAddons,
  currentSubsciptionDetails: state.stripeData.currentSubsciptionDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getAllPlans: () => dispatch(getAllPlans()),
  getCompanySummary: (companyId) => dispatch(getCompanySummary(companyId)),
  getAllCardsForCustomer: (customerId) =>
    dispatch(getAllCardsForCustomer(customerId)),
  getAllAddons: (companyId) => dispatch(getAllAddons(companyId)),
  getCompanySubscriptionAndAddons: (companyId) =>
    dispatch(getCompanySubscriptionAndAddons(companyId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingTab);
